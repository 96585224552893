import React, { useContext, useEffect } from "react";
import "./Message.css";
import { SocketContext } from "../../../context/SocketContext";

const Message = ({ message: { user, generic }, name }) => {
  const { socket, setMessages, messages } = useContext(SocketContext);
  const trimmedName = name.trim().toLowerCase();
  const isSentByCurrentUser = user === trimmedName;

  const enviaPregunta = (pregunta) => {
    socket.emit("sendMessage", pregunta);
  };

  const buttonUrlClick = (url) => {
    window.open(url);
  };

  useEffect(() => {
    if (generic) {
      generic.forEach((message) => {
        if (message.response_type === "pause") {
          setTimeout(() => {
            setMessages((prevMessages) =>
              prevMessages.map((m) => ({
                ...m,
                generic: m.generic.filter((e) => e.response_type !== "pause"),
              }))
            );
          }, message.time);
        }
      });
    }
  }, [generic, setMessages]);

  const renderTextMessage = (text) => {
    const urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g;

    const processedText = [];
    let lastIndex = 0;

    text.replace(urlRegex, (match, label, url, offset) => {
      if (lastIndex < offset) {
        processedText.push(text.slice(lastIndex, offset));
      }
      processedText.push(
        <a
          key={offset}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="messageLink"
        >
          {label}
        </a>
      );
      lastIndex = offset + match.length;
    });

    if (lastIndex < text.length) {
      processedText.push(text.slice(lastIndex));
    }

    return (
      <div className="messageContainer justifyStart">
        <div className="messageBox backgroundLight message-box-adjust1">
          <p className="messageText colorDark">{processedText}</p>
        </div>
      </div>
    );
  };

  const renderImageMessage = (source) => (
    <div className="messageContainer justifyStart">
      <div className="messageBox backgroundLight message-box-adjust1">
        <img src={source} className="messageImg" alt="icon" />
      </div>
    </div>
  );

  const renderButtonOption = (description, options) =>
    options.map((option, i) => (
      <div key={i} className="messageContainer justifyStart">
        <div className="messageBox backgroundLight message-box-adjust1">
          <p className="messageText colorDark">{description}</p>
          <button
            className="buttonText"
            onClick={() => buttonUrlClick(option.value.input.text)}
          >
            {option.label}
          </button>
        </div>
      </div>
    ));

  const renderRelatedQuestions = (description, options) => (
    <div>
      <p className="fq-text">{description}</p>
      {options.map((option, i) => (
        <div key={i} className="messageContainer justifyStart">
          <button
            className="button messageBox backgroundOrange message-box-adjust1"
            onClick={() => enviaPregunta(option.label)}
          >
            {option.label}
          </button>
        </div>
      ))}
    </div>
  );

  const renderVideoMessage = ({ source, title, alt_text }) => {
    return (
      <div className="messageContainer justifyStart">
        <div className="messageBox backgroundLight message-box-adjust1">
          <div className="videoContainer">
            <iframe
              src={source.replace("watch?v=", "embed/")}
              title={title || alt_text || "Video"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="videoIframe"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {generic ? (
        isSentByCurrentUser ? (
          <div className="messageContainer justifyEnd">
            <div className="messageBox backgroundBlue message-box-adjust2">
              <p className="messageText colorWhite">{generic[0].text}</p>
            </div>
          </div>
        ) : (
          generic.map((elemento, i) => {
            switch (elemento.response_type) {
              case "text":
                return <div key={i}>{renderTextMessage(elemento.text)}</div>;
              case "image":
                return <div key={i}>{renderImageMessage(elemento.source)}</div>;
              case "video":
                return <div key={i}>{renderVideoMessage(elemento)}</div>;
              case "option":
                if (elemento.title === "_btn") {
                  return renderButtonOption(
                    elemento.description,
                    elemento.options
                  );
                } else if (elemento.title === "_btnLst") {
                  return renderRelatedQuestions(
                    "Preguntas relacionadas a este tema -",
                    elemento.options
                  );
                } else if (elemento.title === "_btnUrl") {
                  return renderButtonOption(
                    elemento.description,
                    elemento.options
                  );
                }
                break;
              case "pause":
                return (
                  <div key={i}>
                    <div className="messageContainer justifyStart">
                      <div className="messageBox backgroundLight message-box-adjust1">
                        <p className="messageText colorDark">...</p>
                      </div>
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })
        )
      ) : null}
    </>
  );
};

export default Message;
